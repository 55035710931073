import IndexPage from 'components/pages/index/IndexPage';
import Seo from 'components/Seo';
import { graphql } from 'gatsby';
import * as React from 'react';

const Index = () => (
	<div>
		<Seo
			title="Home page | FINTEQ"
			keywords="finance for companies, supplier financing, cheap finance, supply chain, risk-free, liquidity, payout invoices"
			description="Discover the powerful possibilities of FINTEQ. Get financing for your company, payout invoices before the due date or launch your own funding programme."
			lang="en-US"
			locale="en_US"
		/>

		<IndexPage/>
	</div>
);

export default Index;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
